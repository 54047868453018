import React, {useState, useContext} from 'react'

import { appContext } from "../App";

import { IoCloseCircle } from "react-icons/io5";
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';


function MarkInOrOut(props) {
    const [selOption, setSelOption] = useState('');
    const [inDate, setInDate] = useState(null);
    const [outDate, setOutDate] = useState(null);
    const [selReason, setSelReason] = useState('');
    const [isReturnable, setIsReturnable] = useState(false);
    const client = useContext(appContext).client;
    const clientMethods = useContext(appContext).clientMethods;

    const handleSelReason = (event) => {
        setSelReason(event.target.value)
    }

    const handleSelectChange = (event) => {
        setSelOption(event.target.value);
    }

    const handleSubmit = async() => {
        let ioro = 'in';
        let status = '';
        let returnable = '0';
        if (selOption !== 'In'){
            ioro = 'out'
            status = selReason;
            returnable = isReturnable ? "1" : "0"
        }
        const data = await clientMethods.markoutorin(client.currResId, status, returnable, ioro);
        console.log(data)
    }

    return (props.trigger) ? (
    <>
        <link rel="stylesheet" href="/css/components/MarkInOrOut.css"/>
        <div id='markinorout'>
            <div id='markinoroutCont'>
                <IoCloseCircle id='markIoOClose' className='icon' onClick= {() => {props.setTrigger(false)}} />
                <h4 id='markTitle'>Mark Resident In/ Out</h4>
                <hr></hr>
                <div id='radioChoiceCont'>
                    <input className='radioChoice' checked={selOption === 'In'} onChange={handleSelectChange} type="radio" id="choiceIn" name='radioChoice' value="In" />
                    <label className='choiceLabel' for="choiceIn">In</label>

                    <input className='radioChoice' checked={selOption === 'Out'} onChange={handleSelectChange} type="radio" id="choiceOut" name='radioChoice' value="Out" />
                    <label className='choiceLabel' for="choiceOut">Out</label>
                </div>

                {selOption==='In' && <div id='inCont'>
                    <hr className='inHr'></hr>
                    <div id='dateInHolder'>
                    <DateTimePicker 
                        id='inCalendar' 
                        label='Date/Time of Admission'
                        dateFormat="MM-dd-yyyy HH:mm"
                        value={inDate}
                        onChange={(newValue) => {
                            setInDate(newValue)
                        }}/>

                        <div id='inRightNowCont'>
                            <input type='checkbox' id='inRightNow' onChange={() => {
                                if(document.getElementById('inRightNow').checked)
                                    setInDate(dayjs())
                                else
                                    setInDate(null)
                                }}/>
                            <label id='return_label' for='inRightNow'>Right Now?</label>
                        </div>
                    </div >

                        
                </div>}

                
                {selOption==='Out' && <div id='outCont'>
                    <hr className='inHr'></hr>
                    <div id='dateOutHolder'>
                        <DateTimePicker 
                            id='outCalendar' 
                            label='Date/Time of Departure'
                            dateFormat="MM-dd-yyyy HH:mm"
                            value={outDate}
                            onChange={(newValue) => {
                                setOutDate(newValue)
                            }}/>

                        <div id='outRightNowCont'>
                            <input type='checkbox' id='outRightNow' onChange={() => {
                                if(document.getElementById('outRightNow').checked)
                                    setOutDate(dayjs())
                                else
                                    setOutDate(null)
                                }}/>
                            <label id='return_label' for='inRightNow'>Right Now?</label>
                        </div>
                    </div>
                    
                    <select id='slctReason' value={selReason} onChange={handleSelReason}>
                        <option value='Hospital'>Hospital</option>
                        <option value='Dialysis'>Dialysis</option>
                        <option value='Physical Therapy'>Physical Therapy</option>
                        <option value='Family'>Family</option>
                        <option value='Other'>Other</option>
                    </select>

                    {(selReason === 'Other' || selReason === 'Hospital') && <form>
                            <textarea id='otherReason' placeholder='Reason' style={{resize:'none'}} />
                        </form>
                    }

                    <div id='returnContainer'>
                        <div id='checkCont'>
                            <input type='checkbox' id='isReutrnable' onChange={() => {setIsReturnable(!isReturnable)}}/>
                            <label id='return_label' for='isReturnable'>Will Resident Be Returning?</label>
                        </div>

                    </div>


                </div>}

                {selOption !== '' && <button id='submitInOrOut' onClick={async () => {
                    await handleSubmit();
                    props.submitVal();
                    }}>Submit</button>}
            </div>
        </div>
    </>
  ) : "";
}

export default MarkInOrOut