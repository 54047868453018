import React from 'react'

const PromotionImage = (props) =>  {
  return (
    <div className='promotionImageCont'>
      <link rel="stylesheet" href="/css/components/promotionImage.css"/>
        <img src={props.img} alt='img' className='promoImg' id={'img' + props.index} />
        <p id={'desc' + props.index} className='promoDesc'>{props.desc}</p>
        <p className='promoTime'>{props.time}</p>
    </div>
  )
}

export default PromotionImage