import React, {useState, useContext} from 'react'

import Navbar from '../components/Navbar';
import { appContext } from "../App";

function ContactPage() {
    const clientMethods = useContext(appContext).clientMethods;
    const [typeSel, setType] = useState('');

    function handleSelType(event){
        setType(event.target.value);
    }

    async function handleSubmit() {
        let name = document.getElementById('nameTxt').value
        let email = document.getElementById('emailTxt').value
        let val = typeSel
        if (val === 'Other')
            val += ' - ' + document.getElementById('otherType').value
        let notes = document.getElementById('questionTxt').value

        if (name === '' || email === '' || val === 'Other - ' || notes === '')
        {} else{
            let data = await clientMethods.contactus(name, email, val, notes);
            console.log(data)
        }
    }

  return (
    <div id='ContactUs'>
        <link rel="stylesheet" href="/css/pages/Contact.css"/>
        <Navbar page='Login'/>
        <div id='ContactUsContainer'>
            <h2>Have a Question? Ask it!</h2>
            <h3>Reach out with any questions and we will answer within 24 hours!</h3>
            <div id='formContainer'>
                <form id='contactForm'>
                    <div style={{width: '90%', display: 'flex', flexDirection:'row'}}>
                        <input id='nameTxt' className='halfLine' placeholder='Name' required/>
                        <input id='emailTxt' className='halfLine' placeHolder='Email' required/>
                    </div>
                    <select id='slctType' value={typeSel} onChange={handleSelType} required>
                        <option value="" disabled selected>Select type of question</option>
                        <option value='Sales'>Sales</option>
                        <option value='Support'>Support</option>
                        <option value='Other'>Other</option>
                    </select>

                    {typeSel === 'Other' && <input id='otherType' placeholder='What kind of question?' required/>}
                    <textarea id='questionTxt' placeholder='Question' required/>
                    <input type = 'submit' id='submitForm'  value='Submit' onClick={handleSubmit}/>
                </form>
            </div>
        </div>
    </div>
  )
}

export default ContactPage