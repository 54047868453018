import React from "react";

function LogoutWarning(props){
    return (props.trigger) ? (
        <div>
            <link rel="stylesheet" href="/css/components/LogoutWarning.css"/>
            <div id="LogoutWarning">
                <div id="LogoutWarningContainer">
                    <h2 id="logWarn">You are about Logout!</h2>
                    <h3 id='logWarnConf'>Please Confirm you want to logout</h3>
                    <div id='logBtnCont'>
                        <button id="logCanc" onClick={() => {props.setTrigger(false)}}>Cancel</button>
                        <button id="logConf" onClick={() => {props.logout()}}>Logout</button>
                    </div>
                </div>
            </div>
        </div>
    ) : "";
}

export default LogoutWarning;