import React, {useState} from "react";

import { FaBars } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { IconContext } from "react-icons/lib";

import {landingSideOptions} from "../navbardata/landingsideoptions"
import {appSideOptions} from "../navbardata/appsideoptions"

function HamburgerOptionsSidebar(props) {
    const [sidebar, setSideBar] = useState(false)
    const showSideBar = () => [setSideBar(!sidebar)]
    const navigate = useNavigate();
    let data = "";
    if(props.page === 'Landing' || props.page === 'Login'){
        data = landingSideOptions;
    } else{
        data = appSideOptions;
    }
    return(
        <div className="navbarCont">
                <link rel="stylesheet" href="/css/components/HamburgerSideOpt.css"/>
                <div className="navbar">
                    <Link to='#' className='menu-bars' onClick={showSideBar} >
                        <FaBars /> 
                    </Link>
                </div>
                <IconContext.Provider value={{color:"f5f5f5"}}>
                <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                    <ul className="navMenuItems" onClick={showSideBar}>
                        <li className="navbarToggle">
                            <Link to='#' className = 'menu-bars'>
                                <AiOutlineClose />
                            </Link>
                        </li>

                        {data.map((item, index) => {
                            if (item.title === 'Resident Page' && props.client.currResId === -1){
                                return "";
                            } else if(item.title === 'Pick Residents' && props.client.resCount === 1) {
                                return "";
                            } else if (item.title === 'Admin' && props.client.isAdmin === "false"){
                                return "";
                            }
                            return (
                                <li key={index} className={item.cName} onClick={() => {
                                    item.title === 'Logout' ? props.logoutClicked() : navigate(item.path)
                                }} >
                                    {item.icon}
                                    <h5>
                                        {item.title}
                                    </h5>
                                </li>
                            )
                        })}
                    </ul>
                </nav>
        </IconContext.Provider>

            </div>
    )
}

export default HamburgerOptionsSidebar;