import React, {useState, useContext, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';

import { appContext } from "../App";

import Navbar from '../components/Navbar';
import LogoutWarning from "../components/LogoutWarning";
//addUser, addResidentToUser, resetPassword, addResident
function AdminPage() {
    const navigate = useNavigate();
    const [logPopup, setlogPopup] = useState(false);

    const client = useContext(appContext).client;
    const clientMethods = useContext(appContext).clientMethods;
    const currRes = useContext(appContext).currRes;

    useEffect(() => {
        if(sessionStorage.getItem('client') === null){
            navigate('/login')
        } else if(client.ticket === 'null'){
            clientMethods.setInfoFromClient(sessionStorage.getItem("client"));
        } else if(sessionStorage.getItem('currRes') === null){
            navigate('/pick')
        } else if(currRes.ID === -1){
            currRes.resFromSession(sessionStorage.getItem("currRes"));
        }
    })

    if(sessionStorage.getItem('client') === null){
        navigate('/login')
    }

    if(client.ticket === 'null')
        clientMethods.setInfoFromClient(sessionStorage.getItem("client"));
    
    if(currRes.ID === -1 && sessionStorage.getItem('currRes') !== null)
        currRes.resFromSession(sessionStorage.getItem("currRes"));

    const logoutClicked = () => {
        setlogPopup(!logPopup)
    }

    async function handleLogOut(){
        clientMethods.logout();
        sessionStorage.clear();
        navigate('/login')
    }

    
  return (
    <div id='AdminPage'>
        <link rel="stylesheet" href="/css/pages/Admin.css"/>
        <Navbar page='App'logoutClicked={logoutClicked} client={client}/>
        <LogoutWarning trigger={logPopup} setTrigger={setlogPopup} logout={handleLogOut} />
        <div id='AdminPageContainer'>
            <h2>Available Admin Functions: </h2>
            <button style={{marginLeft:'20px'}}>Add User</button>
            <button style={{marginLeft:'20px'}}>Add Resident To User</button>
            <button style={{marginLeft:'20px'}}>Reset Password</button>
            <button style={{marginLeft:'20px'}}>Add Resident</button>
        </div>
    </div>
  )
}

export default AdminPage