import React, {useState, useContext} from "react";
import { useNavigate } from "react-router-dom";

import { appContext } from "../App";

import Navbar from "../components/Navbar";
import LogoutWarning from "../components/LogoutWarning";

const ResidentMessages = () =>{
    const navigate = useNavigate();
    const [logPopup, setlogPopup] = useState(false);
    const clientMethods = useContext(appContext).clientMethods;
    const client = useContext(appContext).client;

    const logoutClicked = () => {
        setlogPopup(!logPopup)
    }

    async function handleLogOut(){
        clientMethods.logout();
        sessionStorage.clear();
        navigate('/login')
    }

    return (
        <div id = 'Messages'>
            <link rel="stylesheet" href="/css/pages/ResidentMessages.css"/>
            <Navbar page="App" logoutClicked={logoutClicked} client={client}/>
            <div id='messagesContainer'>
                <LogoutWarning trigger={logPopup} setTrigger={setlogPopup} logout={handleLogOut} />
                <div id='unreadContainer'>
                    <h3 id='un-txt'>Unread:</h3>
                    <div id='unRmsgContainer'>
                    <div className='unReadMsg'>
                            <div className='msg-text msgFrom'>
                                <p>From</p>
                            </div>
                            <div className='msg-text msgSubject'>
                                <p>Subject</p>
                            </div>
                            <div className='msg-text msgTime'>
                                <p>Time</p>
                            </div>
                        </div>

                        <div className='unReadMsg'>
                            <div className='msg-text msgFrom'>
                                <p>From</p>
                            </div>
                            <div className='msg-text msgSubject'>
                                <p>Subject</p>
                            </div>
                            <div className='msg-text msgTime'>
                                <p>Time</p>
                            </div>
                        </div>

                        <div className='unReadMsg'>
                            <div className='msg-text msgFrom'>
                                <p>From</p>
                            </div>
                            <div className='msg-text msgSubject'>
                                <p>Subject</p>
                            </div>
                            <div className='msg-text msgTime'>
                                <p>Time</p>
                            </div>
                        </div>

                    </div>
                    
                    <button id='viewMsg'>View Read Messages</button>
                </div>
                
                <div id='writeMsgContainer'>
                    <h3>Write Message:</h3>
                    <h4 id='to-txt'>To:</h4>
                    <select id='slctTo'>
                        <option value='Patient'>Patient</option>
                        <option value='Staff'>Staff</option>
                    </select>

                    <div id='wrHeader'>
                        <h4 id='wrHeadmsg'>Message:</h4>
                        <p id='wrHeadcount'>/5000</p>
                    </div>

                    <p id = 'wrMessage' contentEditable='true'></p>

                    <button id='wrSubmit'>Submit</button>
                </div>
                
            </div>
        </div>
    );
}

export default ResidentMessages;