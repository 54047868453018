import React, {useContext, useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";

import { appContext } from "../App";

import { FaSearch } from "react-icons/fa";

import Navbar from "../components/Navbar";
import LogoutWarning from "../components/LogoutWarning";

const PickResidents = () =>{
    const navigate = useNavigate();
    const client = useContext(appContext).client;
    const clientMethods = useContext(appContext).clientMethods;
    const currRes = useContext(appContext).currRes;

    const [logPopup, setlogPopup] = useState(false);
    const [showEmpty, setShowEmpty] = useState(false);
    const [showNoRes, setShowNoRes] = useState(false);
    const [resResults, setResResults] = useState(JSON.parse(client.availRes));
    
    
    useEffect(() => {
        if(sessionStorage.getItem('client') === null){
            navigate('/login')
        }

        if(client.ticket === 'null'){
            clientMethods.setInfoFromClient(sessionStorage.getItem("client"));
        }

        if (client.resCount === 0 || client.resCount > 5){
            searchBar();
        } else{
            let pts = JSON.parse(client.availRes);
            noSearchBar(pts);
        }
    });

    function searchBar() {
        document.getElementById('searchBarContainer').style.display = 'flex';
    }


    function handleSubmit (e){
        e.preventDefault();
        let srch = document.getElementById('searchTerm').value;
        if (srch.trim() === '')
            setShowEmpty(true)
        else{
            setShowEmpty(false)
            searchBtnClk(srch)
        }
    }

    async function searchBtnClk(srch){
        const data = await clientMethods.residentSearch(srch)
        let pts2 = JSON.parse(data);
        setResResults(pts2)
        if(pts2.length === 0)
            setShowNoRes(true)
        else{
            setShowNoRes(false)
        }
    }

    function noSearchBar(pts){
        document.getElementById('searchBarContainer').style.display = 'none';
    }


    const logoutClicked = () => {
        setlogPopup(!logPopup)
    }

    async function handleLogOut(){
        clientMethods.logout();
        sessionStorage.clear();
        navigate('/login')
    }

    return (
        <div id="PickResidentPage">
            <link rel="stylesheet" href="/css/pages/PickResidents.css"/>
            <Navbar page='App' logoutClicked={logoutClicked} client={client}/>
            <LogoutWarning trigger={logPopup} setTrigger={setlogPopup} logout={handleLogOut} />
            
            <div id='pickResidentPageContainer'>
                <div id='searchBarContainer'>
                    <form id="searchForm" onSubmit={handleSubmit}>
                        <label id='searchLabel' for='searchTerm'>Search: </label>
                        <input type="text" id='searchTerm' placeholder="Resident Name/ Bed/ ID" ></input>
                    </form>

                    <FaSearch class='icon' id="searchBtn" onClick={() => {
                        searchBtnClk(document.getElementById('searchTerm').value)
                    }} />
                </div>
                {showEmpty && <h3 style={{color:'red'}}>Please enter a valid search</h3>}
                <div id="resultsContainer">
                    <p id="residentTag">Residents: </p>
                    <div id='residentResultContainer'>
                        {resResults.map((item, index) => {
                            let name = item.FirstName;
                            if(item.NickName != null){
                                name += " '" + item.NickName + "'";
                            }
                            name += " " + item.LastName;
                            
                            let id = item.PatientId;
                            let bed = item.BedName;
                            let pic = clientMethods.getPatientPicture(id);
                            return (
                            <div className='resCont'>
                            <div className='residentContainer' 
                                 onClick={async () => {
                                    client.currResId = id;

                                    let data = await clientMethods.getPatientInfo(client.currResId)
                                    currRes.resFromString(data)

                                    data = await clientMethods.getPatientPastInteraction(client.currResId)
                                    currRes.actionsFromString(data)

                                    sessionStorage.setItem('client', JSON.stringify(client))
                                    sessionStorage.setItem('currRes', JSON.stringify(currRes))
                                    navigate("/user")
                                    } }>
                                <div className='resContLeft'>
                                    <img className='resPic' alt='prop_pic' src={pic}></img>
                                </div>
                                <div className='resContRight'>
                                    <p className="infoTag">{name}</p>
                                    <p className="infoTag">ID: {id}</p>
                                    <p className="infoTag">Bed: {bed}</p>
                                </div>
                            </div>
                        </div>);
                        })}
                        <div id="lastContainer"></div>
                    </div>
                    {showNoRes && <h3 style={{color:'red'}}>No residents match search entered, please try again</h3>}
                </div>
                
            </div>
        </div>
    );
}


export default PickResidents;