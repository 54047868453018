import React, {useContext} from "react";
import { Link } from "react-router-dom";

import { appContext } from "../App";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";

const LoginPage = () =>{
    const navigate = useNavigate();
    const client = useContext(appContext).client;
    const clientMethods = useContext(appContext).clientMethods;
    const currRes = useContext(appContext).currRes;
    sessionStorage.clear();

    const handleClick = async () => {
        let userID = document.getElementById('userNameEdt').value;
        let pass = document.getElementById('passwordEdt').value;
        
        const data = await clientMethods.sendLogin(userID, pass);
        if (data !== 'NOT_LOGGED_IN'){
            clientMethods.setInfo(data)
            sessionStorage.setItem("client", JSON.stringify(client));
            if(client.resCount === 1){
                let data = await clientMethods.getPatientInfo(client.currResId)
                currRes.resFromString(data)

                data = await clientMethods.getPatientPastInteraction(client.currResId)
                currRes.actionsFromString(data)

                sessionStorage.setItem('currRes', JSON.stringify(currRes))
                navigate("/user")
            } else{
                navigate('/pick');
            }
        }
    }

    return ( 
        <div id = 'loginPage'>
            <link rel="stylesheet" href="/css/pages/LoginPage.css"/>
            <Navbar page='Login'/>
            <div id='loginPageContainer'>

                <div id="login-infoContainer">
                    <div id='logoCont'>
                        <div id='logoLeftCont'>
                            <img id='login-logo' src='/assets/angelcare_logo.png' alt='logo here.png'/>
                        </div>
                        <div id='logoRightCont'>
                            <h2>Angel Care</h2>
                            <h3>Taking Care of your Angels</h3>
                        </div>
                    </div>
                    <p id='login-blurb'>Hi there,
                        We are Angel Care! Our mission is to provide visbility and accountability for loved ones that are living in nursing homes all over the country. Have any questions? Please reach out, we would love to be of any help :)
                    </p>
                </div>

                <div id='loginBoxContainer'>
                    <h2 id='loginTitle'>Login</h2>
                    <div id='userNpassContainer'>
                        <form>
                            <input placeholder="Username" id="userNameEdt" />
                            <input type="password" placeholder="Password" id="passwordEdt" />
                        </form>

                        <Link id="forgotPass">Forgot Password?</Link>

                        <div>
                            <input type='checkbox' id='remember'/>
                            <label id='remem_label' for='remember'>Remember Me?</label>
                        </div>
                    </div>

                    <button id='submit' onClick={handleClick}> 
                        Login
                    </button>
                    
                </div>
            </div>
        </div>
    );
}

export default LoginPage;