import React, {useState} from "react";

const ResidentInteraction = (props) => {
    const [showInfo, setShowInfo] = useState(false);

    return(<div className="interaction" id={"interaction" + props.ID} onClick={() => {setShowInfo(!showInfo)}}>
        <div className="topCont">
            <p className="interaction_action">{props.PatientActionName}</p>
            <p className="interaction_date">{props.PatientActionDate}</p>
        </div>
        {showInfo && <div className="bottomCont">
            <p style={{fontSize:'1.1rem'}}>Notes: </p>
            {JSON.parse(props.notes).map((item) => {
                if (Object.keys(item)[0] === 'Notes')
                    return <p className="interaction_notes">{Object.values(item)[0]}</p>
                else
                    return <p className = "interaction_notes">{Object.keys(item)[0]}: {Object.values(item)[0]}</p>
            })}
        </div>}

    </div>);
}

export default ResidentInteraction;